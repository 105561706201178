<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
        @click-row="clickSummaryRow"
      >
        <template
          #extraSubtitle
        >
          <v-chip
            v-if="formattedItem.es_incidencia"
            dark
            small
            color="red"
            label
            class="ml-1"
          >
            Incidencia
          </v-chip>
          <v-chip
            v-if="!parteNovedadPendiente"
            dark
            small
            color="info"
            label
            class="ml-1"
          >
            Estado del parte {{ parteNovedad.estado_descripcion }}
          </v-chip>
        </template>
      </b10-view-summary>
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
      <b10-file-gallery
        v-if="hasViewPerm(permissions.lparteNovedadAdjunto.id)"
        class="pa-3"
        :images="ficherosGaleria"
      />
      <b10-fab-button
        v-if="parteNovedad && lparteNovedadEditable && hasInsertPerm(permissions.lparteNovedadAdjunto.id)"
        :icon="$vuetify.icons.values.attach"
        @click="clickAddAdjunto"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './LparteNovedadViewData'
import { get } from 'vuex-pathify'
import { LPARTE_NOVEDAD, PARTE_NOVEDAD, TABLA } from '@/utils/consts'
import B10FileGallery from '../../../components/B10FileGallery'
import { getLatLongURL } from '@/utils/maps'

export default {
  components: { B10FileGallery },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  props: {
    editRoute: {
      type: String,
      default: '',
    }
  },
  data () {
    return {
      toolbarOptions: {
        edit: {
          title: 'Modificar',
          visible: true,
          icon: 'edit'
        },
        finalizar: {
          title: 'Finalizar',
          icon: 'finalizar',
          visible: true
        },
        pendiente: {
          title: 'Pasar a pendiente',
          visible: true,
          icon: 'undo'
        },
        delete: {
          title: 'Eliminar',
          icon: 'delete',
          visible: true
        },
        irAParteNovedad: {
          title: 'Ir al parte',
          visible: true
        },
      },
      moreInfoRows: [
        { name: 'fnovedad', filter: this.$options.filters.shortDateTime },
        'tnovedad_descripcion',
        'descripcion',
        'estado_descripcion',
        { name: 'fuerza_seguridad_descripcion', label: 'Entidad notificada'},
        { name: 'puesto_servicio_servicio_desc', label: 'Servicio'},
        { name: 'informe_cliente', filter: this.$options.filters.humanizeBoolean },
        { name: 'geolocalizacion', label: 'Geolocalización', clickable: true },
        'punto_codigo',
        'punto_descripcion',
        { name: 'idparte_novedad', label: 'Parte de novedad' },
      ],
      showingDialogs: {
        moreInfo: false,
      },
      parteNovedad: {},
      ficherosGaleria: [],
      PARTE_NOVEDAD,
      detailName: {
        adjuntos: 'adjuntos',
        datos: 'datos'
      },
      parteNovedadPendiente: false,
      lparteNovedadEditable: false,
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    usuarioIdvigilante: get('usuario/idvigilante'),
    presenciaIdpuestoServicio: get('presencia/idpuestoServicio'),
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idlparte_novedad
        item.fechaNovedad = this.$options.filters.shortDateTime(item.fnovedad)
        item.title = item.fechaNovedad
        item.subtitle = [item.tnovedad_descripcion, item.descripcion]
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        item.badge = LPARTE_NOVEDAD.descripcionEstado[item.estado]
        item.badgeColor = LPARTE_NOVEDAD.coloresInteger[item.estado]
        item.geolocalizacion = `${item.latitud}, ${item.longitud}`
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = `Novedad de parte Nº${this.routeParams.idlparte_novedad}`
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectLparteNovedad(this, this.routeParams.idlparte_novedad)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      const resp = await Data.selectDetail(
        this,
        this.routeParams.idlparte_novedad,
        !!this.usuarioIdtecnico,
        !!this.usuarioIdcomercial,
        !!this.usuarioIdvigilante,
        this.item.dataset.idparte_novedad,
      )
      // parte de novedad
      const datasetParteNovedad = resp.data.selectParteNovedad.result.dataset[0]
      this.parteNovedad = datasetParteNovedad
      this.parteNovedadPendiente = this.parteNovedad.estado === PARTE_NOVEDAD.estados.pendiente
      const idpuestoServicio = this.routeParams.idpuesto_servicio ? this.routeParams.idpuesto_servicio : datasetParteNovedad.idpuesto_servicio
      const esElMismoPuestoQueEnPresencia = idpuestoServicio == this.presenciaIdpuestoServicio
      this.lparteNovedadEditable = this.parteNovedadPendiente && this.item.dataset.estado === LPARTE_NOVEDAD.estados.pendiente && esElMismoPuestoQueEnPresencia
      this.toolbarOptions.edit.visible = this.lparteNovedadEditable
      this.toolbarOptions.delete.visible = this.parteNovedadPendiente && esElMismoPuestoQueEnPresencia
      this.toolbarOptions.finalizar.visible = this.lparteNovedadEditable
      this.toolbarOptions.pendiente.visible = this.parteNovedadPendiente && this.item.dataset.estado === LPARTE_NOVEDAD.estados.finalizado && esElMismoPuestoQueEnPresencia
      // datos dinámicos
      const datasetDatoLparteNovedad = resp.data.selectLparteNovedadDato.result.dataset
      if (this.hasViewPerm(this.permissions.lParteNovedadDato.id)) {
        const detailDatos = this.addDetail(
          this.detailName.datos, 'Datos', 'Datos personalizados', 'datoPersonalizado'
        )
        detailDatos.badge = datasetDatoLparteNovedad.length || 0
      }
      // adjuntos de todas las novedades
      if (this.hasViewPerm(this.permissions.lparteNovedadAdjunto.id)) {
        const datasetFicheros = resp.data.selectFichero.result.dataset
        const detailFicheros = this.addDetail(
          this.detailName.adjuntos, 'Adjuntos', 'Fotografías y ficheros adjuntos', 'attach'
        )
        detailFicheros.badge = datasetFicheros.length || 0
        for (const fichero in datasetFicheros) {
          datasetFicheros[fichero]['blobUrl'] = await this.$api.getBlobUrl(datasetFicheros[fichero])
        }
        this.ficherosGaleria = datasetFicheros
      }
    },
    clickDetail (data) {
      let detailRoute = {
        name: null
      }
      switch (data.detail.name) {
      case this.detailName.adjuntos:
        detailRoute = {
          name: 'ficheros__partes-novedad-novedades-fichero-list',
          params: {
            id: this.routeParams.idlparte_novedad,
            idtabla: TABLA.lparte_novedad.idtabla,
            idparte_novedad: this.item.dataset.idparte_novedad,
            readonly: !this.lparteNovedadEditable,
          },
        }
        break
      case this.detailName.datos:
        detailRoute = {
          name: 'datos__novedades-dato-list',
          params: {
            id: this.routeParams.idlparte_novedad,
            idtabla: TABLA.lparte_novedad.idtabla,
            readonly: !this.lparteNovedadEditable,
          },
        }
        break
      default:
        console.error(`La opción ${data.detail.name} no está contemplada`);
      }
      if (detailRoute.name !== null) {
        this.$appRouter.push(detailRoute)
      }
    },
    async clickToolbarOption (option) {
      let res = null
      switch (option) {
      case this.toolbarOptions.edit:
        this.$appRouter.push({
          name: this.editRoute,
          params: {
            idlparte_novedad: this.routeParams.idlparte_novedad,
            idparte_novedad: this.routeParams.idparte_novedad,
          },
        })
        break
      case this.toolbarOptions.delete:
        res = await this.$alert.showConfirm(
          `¿Deseas eliminar la novedad Nº${this.routeParams.idlparte_novedad}?`
        )
        if (res) {
          await Data.deleteLparteNovedad(this, this.routeParams.idlparte_novedad)
          await this.$dirty.deleted(this.$dirty.ENTITIES.remote.lparteNovedad, this.routeParams.idlparte_novedad)
          this.$alert.showSnackbarSuccess('Novedad eliminada')
          this.$appRouter.go(-1)
        }
        break
      case this.toolbarOptions.finalizar:
        res = await this.$alert.showConfirm(
          `¿Deseas finalizar la novedad Nº${this.routeParams.idlparte_novedad}?`
        )
        if (res) {
          await Data.cambiarEstadoLparteNovedad(this, this.routeParams.idlparte_novedad, LPARTE_NOVEDAD.estados.finalizado)
          await this.$dirty.modified(this.$dirty.ENTITIES.remote.lparteNovedad, this.routeParams.idlparte_novedad)
          this.$alert.showSnackbarSuccess('Novedad finalizada')
          this.$appRouter.go(-1)
        }
        break
      case this.toolbarOptions.pendiente:
        res = await this.$alert.showConfirm(
          `¿Deseas volver a pendiente la novedad Nº${this.routeParams.idlparte_novedad}?`
        )
        if (res) {
          await Data.cambiarEstadoLparteNovedad(this, this.routeParams.idlparte_novedad, LPARTE_NOVEDAD.estados.pendiente)
          await this.$dirty.modified(this.$dirty.ENTITIES.remote.lparteNovedad, this.routeParams.idlparte_novedad)
          this.$alert.showSnackbarSuccess('Novedad actualizada')
          this.loadPage()
        }
        break
      case this.toolbarOptions.irAParteNovedad:
        this.$appRouter.push({
          name: 'partesnovedad__parte-novedad-view',
          params: {
            idparte_novedad: this.item.dataset.idparte_novedad
          },
        })
        break
      default:
        console.error(`La opción ${option} no está contemplada`);
      }
    },
    async clickAddAdjunto () {
      this.$appRouter.push({
        name: 'ficheros__partes-novedad-novedades-fichero-add',
        params: {
          idtabla: TABLA.lparte_novedad.idtabla,
          id: this.routeParams.idlparte_novedad
        }
      })
    },
    clickSummaryRow (row) {
      if (row.name === 'geolocalizacion') {
        window.open(getLatLongURL(this.item.dataset.latitud, this.item.dataset.longitud), '_blank')
      }
    },
  }
}
</script>
