import { APIFilter } from '@/utils/api'
import { TABLA } from '@/utils/consts'

export default {
  async selectLparteNovedad (Vue, idlparteNovedad) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idlparte_novedad', idlparteNovedad)
    const resp = await Vue.$api.call('lparteNovedad.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectDetail (Vue, idlparteNovedad, esTecnico, esComercial, esVigilante, idparteNovedad) {
    let apiCalls = []
    const apiFilterParteNovedad = new APIFilter()
    apiFilterParteNovedad.addExact('idparte_novedad', idparteNovedad)
    apiCalls.push({ name: 'selectParteNovedad', method: 'parteNovedad.select', params: { filter: apiFilterParteNovedad } })
    // Ficheros
    const apiFilterFicheros = Vue.$online.fichero.buildApiFilterVisualizacion(idlparteNovedad, TABLA.lparte_novedad.idtabla, esTecnico, esComercial, esVigilante)
    apiCalls.push({ name: 'selectFichero', method: 'fichero.select', params: { filter: apiFilterFicheros } })
    // Datos
    const apiFilterDato = new APIFilter()
      .addExact('id', idlparteNovedad)
      .addExact('idtabla', TABLA.lparte_novedad.idtabla)
      .addGTE('estado', 1)
    apiCalls.push({ name: 'selectLparteNovedadDato', method: 'dato.select', params: { filter: apiFilterDato } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async deleteLparteNovedad (Vue, idlparteNovedad) {
    Vue.$api.call('lparteNovedad.delete', {
      idlparte_novedad: idlparteNovedad
    })
  },
  async cambiarEstadoLparteNovedad (Vue, idlparteNovedad, estado) {
    return await Vue.$api.call(
      'lparteNovedad.update',
      {
        values: {
          idlparte_novedad: idlparteNovedad,
          estado: estado,
        },
      }
    )
  },
}
